import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Flats.css";
import peintureImage from "../../assets/colors.jpg";
import revetementImage from "../../assets/4.jpg";
import isolationImage from "../../assets/2.jpg";
import platreriesImage from "../../assets/3.jpg";
import decorationImage from "../../assets/simplelogo.png";
import contactImage from "../../assets/Phone.png";
import { useInView } from "react-intersection-observer";

function Flats() {
    const navigate = useNavigate();
    const menu = [
        {
            id: 1,
            title: "Basic Website Package",
            cover: peintureImage,
            className: "basic-package",
        },
        { id: 2, title: "Standard Website Package", cover: revetementImage },
        { id: 3, title: "E-commerce Website Package", cover: isolationImage },
        { id: 4, title: "Custom Website Package", cover: platreriesImage },
        { id: 5, title: "Logos", cover: decorationImage },
        { id: 6, title: "Cartes De Visite", cover: contactImage },
    ];

    return (
        <div className="BackGround">
            <body>
                <section id="apropos" class="animated-section">
                    <h2>À Propos :</h2>
                    <p>
                        Nous sommes passionnés par la création de sites web sur
                        mesure pour nos clients. Notre objectif est de fournir
                        des solutions numériques innovantes qui répondent aux
                        besoins uniques de chaque client. Grâce à notre
                        expertise et notre engagement, nous travaillons avec
                        vous pour concevoir et développer des sites web
                        exceptionnels qui reflètent votre vision et renforcent
                        votre présence en ligne.
                    </p>
                </section>

                <section id="services">
                    <h2>Nos Services :</h2>
                    <p>
                        Que vous soyez une entreprise cherchant à établir sa
                        présence en ligne, un entrepreneur individuel souhaitant
                        partager votre passion avec le monde, ou une entreprise
                        en pleine expansion nécessitant une plateforme robuste
                        pour présenter vos produits et services, nous sommes là
                        pour vous aider à concrétiser votre vision.
                    </p>
                </section>
            </body>
            {menu.map((item) => (
                <div
                    className="flat"
                    key={item.id}
                    onClick={() => {
                        const targetRoute =
                            item.title === "Basic Website Package"
                                ? "/Basic"
                                : item.title === "Standard Website Package"
                                ? "/Standard"
                                : item.title === "E-commerce Website Package"
                                ? "/Ecommerce"
                                : item.title === "Custom Website Package"
                                ? "/Custom"
                                : item.title === "Logos"
                                ? "/Logopage"
                                : item.title === "Cartes De Visite"
                                ? "/carte"
                                : `/apartment/${item.id}`;

                        navigate(targetRoute);
                    }}
                >
                    <div className="flatTitle">{item.title}</div>
                    <img src={item.cover} alt={item.title} />
                </div>
            ))}

            <section id="equipe">
                <h2>Notre Équipe :</h2>
                <p>
                    Nous travaillons en étroite collaboration avec chaque client
                    pour comprendre leurs objectifs et leurs exigences
                    spécifiques. À travers une approche personnalisée et une
                    attention méticuleuse aux détails, nous concevons et
                    développons des sites web qui reflètent l'essence même de
                    votre marque.
                </p>
            </section>

            {/* <section id="services-details">
                <h2>Nos Services :</h2>
                <p>
                    Que vous recherchiez un site vitrine élégant, un site
                    e-commerce fonctionnel, ou une plateforme web complexe avec
                    des fonctionnalités avancées, nous sommes là pour
                    transformer vos idées en réalité.
                </p>
            </section> */}

            <section id="engagements">
                <h2>Nos Engagements :</h2>
                <p>
                    De la conception initiale au déploiement final, nous nous
                    engageons à fournir un service exceptionnel et un soutien
                    continu pour assurer le succès de votre projet web.
                </p>
            </section>

            <section id="contact">
                <h2>Contact :</h2>
                <p>
                    Explorez nos services et découvrez comment nous pouvons vous
                    aider à propulser votre présence en ligne vers de nouveaux
                    sommets.
                </p>
            </section>
        </div>
    );
}

export default Flats;
