import React, { useEffect } from "react";
import revdesol from "../../assets/revdesol.jpg";
import Revetementimg from "../../assets/wood.jpg";
import "./Standard.css";
import Logo from "../../components/Logo/Logo";

import Footer from "../../components/Footer/Footer";

function Standard() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="revetement-containerr">
            <Logo />

            <div className="image-overlayr">
                <img
                    src={Revetementimg}
                    alt="Revetement"
                    className="background-imager"
                />
                <div>
                    <div className="overlay-textr3r">
                        <body>
                            <header>
                                <h1>Standard Website Package</h1>
                            </header>
                            <section>
                                <h2>Description</h2>
                                <p>
                                    Vous cherchez une solution plus complète
                                    pour votre présence en ligne ? Découvrez
                                    notre Standard Website Package, offrant une
                                    gamme étendue de fonctionnalités et
                                    d'options de personnalisation pour répondre
                                    à vos besoins professionnels.
                                </p>
                            </section>
                            <section>
                                <h2>Fonctionnalités</h2>
                                <ul>
                                    <li>
                                        Page d'Accueil Dynamique : Mettez en
                                        avant vos contenus avec un slider ou du
                                        contenu en vedette.
                                    </li>
                                    <li>
                                        Page À Propos : Partagez votre histoire,
                                        votre mission et vos valeurs avec vos
                                        visiteurs.
                                    </li>
                                    <li>
                                        Page Services ou Produits : Présentez
                                        vos services ou produits de manière
                                        claire et attrayante.
                                    </li>
                                    <li>
                                        Page Portfolio ou Galerie : Mettez en
                                        avant vos réalisations avec style et
                                        élégance.
                                    </li>
                                    <li>
                                        Page de Contact : Facilitez la prise de
                                        contact avec vos clients grâce à un
                                        formulaire intégré et une intégration
                                        Google Maps pour indiquer votre
                                        emplacement.
                                    </li>
                                    <li>
                                        Design Responsive : Assurez une
                                        expérience utilisateur optimale sur tous
                                        les appareils, y compris les smartphones
                                        et les tablettes.
                                    </li>
                                    <li>
                                        Optimisation de Base pour les Moteurs de
                                        Recherche (SEO) : Améliorez votre
                                        visibilité en ligne avec des pratiques
                                        de référencement de base.
                                    </li>
                                </ul>
                            </section>
                            {/* <section>
                                <h2>Prix</h2>
                                <p>
                                    À partir de 1 500€ - 3 000€, notre Standard
                                    Website Package offre un excellent rapport
                                    qualité-prix pour une solution web complète
                                    et professionnelle.
                                </p>
                            </section> */}
                            {/* <footer>
                                <p>
                                    Contactez-nous pour en savoir plus et
                                    commencez à transformer votre présence en
                                    ligne dès maintenant !
                                </p>
                            </footer> */}
                        </body>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Standard;
