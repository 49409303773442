// Peinture.jsx
import React, { useEffect } from "react";
import colors from "../../assets/colors22.jpeg";
import exemple from "../../assets/exemple.jpg";
import Logo from "../../components/Logo/Logo";
import paint from "../../assets/paint.png";
import Footer from "../../components/Footer/Footer";
import colors3 from "../../assets/colors3.jpg";
import "./Basic.css";

function Basic() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div class="page-container">
            <div className="peinture-container">
                <Logo />
                <img src={colors} alt="peinture" className="peintureimg" />

                <div className="image-overlayb">
                    {/* <img
                        src={colors3}
                        alt="peinture"
                        className="peintureimg3"
                    /> */}

                    <div className="image-overlaytextb">
                        <h1>Basic Website Package</h1>
                        <p>
                            <body>
                                <p>
                                    Vous recherchez une solution simple et
                                    efficace pour présenter votre entreprise ou
                                    votre portfolio personnel en ligne ?
                                    Découvrez notre Package de Site Web Basique,
                                    conçu pour répondre à vos besoins dès le
                                    démarrage.
                                </p>

                                <h2>Caractéristiques Principales :</h2>
                                <ul>
                                    <li>
                                        <strong>Page d'Accueil :</strong> Créez
                                        une première impression mémorable pour
                                        vos visiteurs.
                                    </li>
                                    <li>
                                        <strong>Page À Propos :</strong>{" "}
                                        Partagez votre histoire et votre vision.
                                    </li>
                                    <li>
                                        <strong>Page de Contact :</strong>{" "}
                                        Permettez à vos clients de vous
                                        contacter facilement via un formulaire
                                        intégré.
                                    </li>
                                    <li>
                                        <strong>Personnalisation :</strong>{" "}
                                        Personnalisez le design pour refléter
                                        l'identité de votre marque.
                                    </li>
                                </ul>

                                <h2>Idéal Pour :</h2>
                                <ul>
                                    <li>
                                        <strong>Petites Entreprises :</strong>{" "}
                                        Présentez vos produits et services de
                                        manière professionnelle et accessible.
                                    </li>
                                    <li>
                                        <strong>Portfolios Personnels :</strong>{" "}
                                        Mettez en valeur vos compétences et
                                        réalisations de façon percutante.
                                    </li>
                                </ul>

                                {/* <p>
                                    <strong>Prix :</strong> À partir de 500€ -
                                    Un investissement abordable pour établir
                                    votre présence en ligne.
                                </p>

                                <p>
                                    Contactez-nous dès aujourd'hui pour en
                                    savoir plus sur notre Package de Site Web
                                    Basique et commencez à construire votre
                                    présence en ligne dès maintenant !
                                </p> */}
                            </body>
                        </p>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
}

export default Basic;
