import React, { useState } from "react";
import axios from "axios";
import Logo from "../../components/Logo/Logo";
import Footer from "../../components/Footer/Footer";
import Contactimg from "../../assets/contact1.jpg";
import "./Contact.css";

function Contact() {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        message: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(
                "https://api.web3forms.com/submit",
                {
                    ...formData,
                    access_key: "f996d943-6214-40e2-b015-e56a90cdbe24", // Replace with your Web3Forms access key
                    subject: "New Contact Form Submission from Your Website",
                    from_name: "My Website",
                }
            );

            console.log("Form submitted successfully:", response.data);
            alert("Message sent successfully!");
            setFormData({ name: "", email: "", phone: "", message: "" });
        } catch (error) {
            console.error("Error sending message:", error);
            alert("Failed to send message. Please try again later.");
        }
    };

    return (
        <div className="page-container">
            <Logo />

            <div className="contact-container">
                <div className="image-overlayc">
                    <img
                        src={Contactimg}
                        alt="contact"
                        className="Contactimg"
                    />
                    <div className="overlaytextc">
                        <header>
                            <h1>Contact</h1>
                            <p>
                                Vous avez des questions ou vous souhaitez
                                discuter de votre projet ? N'hésitez pas à nous
                                contacter. Nous sommes là pour vous aider !
                            </p>
                            <form
                                className="contact-form"
                                onSubmit={handleSubmit}
                            >
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Nom"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                    className="form-input"
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="E-mail"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                    className="form-input"
                                />
                                {/* <input
                                    type="text"
                                    name="phone"
                                    placeholder="Téléphone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="form-input"
                                /> */}
                                <textarea
                                    name="message"
                                    placeholder="Message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    className="fixed-textarea form-input"
                                ></textarea>
                                <button type="submit" className="form-submit">
                                    Envoyer
                                </button>
                            </form>
                        </header>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Contact;

//1135
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// // import HCaptcha from "react-hcaptcha"; // Import the HCaptcha component
// import Logo from "../../components/Logo/Logo";
// import Footer from "../../components/Footer/Footer";
// import "./Contact.css";
// import Contactimg from "../../assets/contact1.jpg";

// function Contact() {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     const [formData, setFormData] = useState({
//         name: "",
//         email: "",
//         message: "",
//     });

//     const [captchaToken, setCaptchaToken] = useState("");

//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };

//     const handleCaptchaChange = (value) => {
//         setCaptchaToken(value);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!captchaToken) {
//             alert("Please verify that you're not a robot.");
//             return;
//         }
//         try {
//             const secretKey = "ES_30328b7ebb41444186b472433a6f73d5"; // Your secret key (if needed)

//             // Make your API call with the formData and captchaToken
//             // Example using axios:
//             const response = await axios.post("/api/contact", {
//                 ...formData,
//                 captchaToken,
//                 secretKey,
//             });

//             alert("Message sent successfully!");
//             setFormData({ name: "", email: "", message: "" });
//             setCaptchaToken("");
//         } catch (error) {
//             console.error("Error sending message:", error);
//             alert("Failed to send message. Please try again later.");
//         }
//     };

//     return (
//         <div className="page-container">
//             <Logo />
//             <div className="contact-container">
//                 <div className="image-overlayc">
//                     <img
//                         src={Contactimg}
//                         alt="contact"
//                         className="Contactimg"
//                     />
//                     <div className="overlaytextc">
//                         <header>
//                             <h1>Contact</h1>
//                             <p>
//                                 Vous avez des questions ou vous souhaitez
//                                 discuter de votre projet ? N'hésitez pas à nous
//                                 contacter. Nous sommes là pour vous aider !
//                             </p>
//                             <form
//                                 onSubmit={handleSubmit}
//                                 className="contact-form"
//                             >
//                                 <input
//                                     type="text"
//                                     name="name"
//                                     placeholder="Nom"
//                                     className="form-input"
//                                     required
//                                     value={formData.name}
//                                     onChange={handleChange}
//                                 />
//                                 <input
//                                     type="email"
//                                     name="email"
//                                     placeholder="E-mail"
//                                     className="form-input"
//                                     required
//                                     value={formData.email}
//                                     onChange={handleChange}
//                                 />
//                                 <textarea
//                                     name="message"
//                                     placeholder="Message"
//                                     className="fixed-textarea form-input"
//                                     required
//                                     value={formData.message}
//                                     onChange={handleChange}
//                                 ></textarea>
//                                 {/* <HCaptcha
//                                     sitekey="5e1afae8-10c1-490b-95a5-e6350eac8ac8"
//                                     onVerify={(token) =>
//                                         handleCaptchaChange(token)
//                                     }
//                                 /> */}
//                                 <button type="submit" className="form-submit">
//                                     Envoyer
//                                 </button>
//                             </form>
//                         </header>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </div>
//     );
// }

// export default Contact;

// 1123
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha";
// import Logo from "../../components/Logo/Logo";
// import Footer from "../../components/Footer/Footer";
// import "./Contact.css";
// import Platreriesimg from "../../assets/Plaster.jpg";
// import Contactimg from "../../assets/contact1.jpg";
// import Platreriesimg2 from "../../assets/Plaster2.jpg";

// function Contact() {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     const [formData, setFormData] = useState({
//         name: "",
//         email: "",
//         message: "",
//     });

//     const [captchaToken, setCaptchaToken] = useState("");

//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };

//     const handleCaptchaChange = (value) => {
//         setCaptchaToken(value);
//     };

//     // const handleSubmit = async (e) => {
//     //     e.preventDefault();
//     //     if (!captchaToken) {
//     //         alert("Please verify that you're not a robot.");
//     //         return;
//     //     }
//     //     try {
//     //         // Add your secret key obtained from Google reCAPTCHA
//     //         const secretKey = "6LenRLQpAAAAAA5Ypz5IrELhuHsbr5_Ov9hA9xE8";

//     //         await axios.post("/api/contact", {
//     //             ...formData,
//     //             captchaToken,
//     //             secretKey,
//     //         });
//     //         alert("Message sent successfully!");
//     //         setFormData({ name: "", email: "", message: "" });
//     //         setCaptchaToken("");
//     //     } catch (error) {
//     //         console.error("Error sending message:", error);
//     //         alert("Failed to send message. Please try again later.");
//     //     }
//     // };

//     return (
//         <div className="page-container">
//             <Logo />
//             <div className="contact-container">
//                 <div className="image-overlayc">
//                     <img
//                         src={Contactimg}
//                         alt="contact"
//                         className="Contactimg"
//                     />
//                     <div className="overlaytextc">
//                         <header>
//                             <h1>Contact</h1>
//                             <p>
//                                 Vous avez des questions ou vous souhaitez
//                                 discuter de votre projet ? N'hésitez pas à nous
//                                 contacter. Nous sommes là pour vous aider !
//                             </p>
//                             <form
//                                 // onSubmit={handleSubmit}
//                                 className="contact-form"
//                             >
//                                 <input
//                                     type="text"
//                                     name="name"
//                                     placeholder="Nom"
//                                     value={formData.name}
//                                     onChange={handleChange}
//                                     required
//                                     className="form-input"
//                                 />
//                                 <input
//                                     type="email"
//                                     name="email"
//                                     placeholder="E-mail"
//                                     value={formData.email}
//                                     onChange={handleChange}
//                                     required
//                                     className="form-input"
//                                 />
//                                 <textarea
//                                     name="message"
//                                     placeholder="Message"
//                                     value={formData.message}
//                                     onChange={handleChange}
//                                     required
//                                     className="fixed-textarea form-input"
//                                 ></textarea>

//                                 {/* <ReCAPTCHA
//                                     sitekey="6LenRLQpAAAAACNKsLUY_HWRq3uJJZAPRUpiTMFD"
//                                     onChange={handleCaptchaChange}
//                                     className="form-input"
//                                 /> */}
//                                 <button type="submit" className="form-submit">
//                                     Send Message
//                                 </button>
//                             </form>
//                         </header>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </div>
//     );
// }

// export default Contact;

// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import ReCAPTCHA from "react-google-recaptcha";
// import Logo from "../../components/Logo/Logo";
// import Footer from "../../components/Footer/Footer";
// import "./Contact.css";
// import Platreriesimg from "../../assets/Plaster.jpg";
// import Contactimg from "../../assets/contact1.jpg";
// import Platreriesimg2 from "../../assets/Plaster2.jpg";

// function Contact() {
//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     const [formData, setFormData] = useState({
//         name: "",
//         email: "",
//         message: "",
//     });

//     const [captchaToken, setCaptchaToken] = useState("");

//     const handleChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };

//     const handleCaptchaChange = (value) => {
//         setCaptchaToken(value);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (!captchaToken) {
//             alert("Please verify that you're not a robot.");
//             return;
//         }
//         try {
//             await axios.post("/api/contact", { ...formData, captchaToken });
//             alert("Message sent successfully!");
//             setFormData({ name: "", email: "", message: "" });
//             setCaptchaToken("");
//         } catch (error) {
//             console.error("Error sending message:", error);
//             alert("Failed to send message. Please try again later.");
//         }
//     };

//     return (
//         <div className="page-container">
//             <Logo />
//             <div className="contact-container">
//                 <div className="image-overlayc">
//                     <img
//                         src={Contactimg}
//                         alt="contact"
//                         className="Contactimg"
//                     />
//                     <div className="overlaytextc">
//                         <header>
//                             <h1>Contact</h1>
//                             <p>
//                                 Vous avez des questions ou vous souhaitez
//                                 discuter de votre projet ? N'hésitez pas à nous
//                                 contacter. Nous sommes là pour vous aider !
//                             </p>
//                             <form
//                                 onSubmit={handleSubmit}
//                                 className="contact-form"
//                             >
//                                 <input
//                                     type="text"
//                                     name="name"
//                                     placeholder="Nom"
//                                     value={formData.name}
//                                     onChange={handleChange}
//                                     required
//                                     className="form-input"
//                                 />
//                                 <input
//                                     type="email"
//                                     name="email"
//                                     placeholder="E-mail"
//                                     value={formData.email}
//                                     onChange={handleChange}
//                                     required
//                                     className="form-input"
//                                 />
//                                 <textarea
//                                     name="message"
//                                     placeholder="Message"
//                                     value={formData.message}
//                                     onChange={handleChange}
//                                     required
//                                     className="fixed-textarea form-input"
//                                 ></textarea>

//                                 <ReCAPTCHA
//                                     sitekey="6LdSR6spAAAAAJ163BR1afa9t0lz5h6L7n-_xeHB"
//                                     onChange={handleCaptchaChange}
//                                     className="form-input"
//                                 />
//                                 <button type="submit" className="form-submit">
//                                     Send Message
//                                 </button>
//                             </form>
//                         </header>
//                     </div>
//                 </div>
//             </div>
//             <Footer />
//         </div>
//     );
// }

// export default Contact;
