// Peinture.jsx
import React, { useEffect } from "react";
import Decorationimg from "../../assets/simplelogodeux.png";
import Logo from "../../components/Logo/Logo";
import Footer from "../../components/Footer/Footer";
import "./Logopage.css";
import Decorationintimg from "../../assets/decor.jpg";

function Logopage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <Logo />

            <img
                src={Decorationimg}
                alt="Decoration"
                className="Decorationimg"
            />
            <div className="overlaytextdec">
                <body>
                    <header>
                        <h1>Logo</h1>
                    </header>
                    <section>
                        {/* <h2>Nos Services de Conception de Logo</h2> */}
                        <p>
                            Nous travaillons en étroite collaboration avec vous
                            pour comprendre <br></br>vos besoins, votre secteur
                            d'activité et votre public cible, afin de<br></br>{" "}
                            créer un logo qui reflète l'essence de votre
                            entreprise.
                        </p>
                        <ul>
                            <li>Consultation Personnalisée</li>
                            <li>Conception Créative</li>
                            <li>Révisions</li>
                            <li>Fichiers Prêts pour l'Utilisation</li>
                        </ul>
                    </section>
                    <section>
                        {/* <h2>Nos Tarifs</h2> */}
                        {/* <p>
                            Tarif de Base : <strong>À partir de 500 €</strong>
                        </p> */}
                        {/* <p>Nous sommes là pour </p> */}
                    </section>
                    <footer>
                        <p>
                            Prêt à donner à votre entreprise une identité
                            visuelle distinctive ?<br></br> Contactez-nous dès
                            aujourd'hui pour un devis personnalisé.
                            {/* et donner vie
                            à votre vision. */}
                        </p>
                    </footer>
                </body>
            </div>
            <Footer />
        </div>
    );
}

export default Logopage;
