// Peinture.jsx
import React, { useEffect } from "react";
import "./Carte.css";
import Platreriesimg from "../../assets/Plaster.jpg";
import Contactimgg from "../../assets/LogofooterIII.png";
import Platreriesimg2 from "../../assets/Plaster2.jpg";
import Logo from "../../components/Logo/Logo";
import Footer from "../../components/Footer/Footer";
function Carte() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div class="page-container">
            <div>
                <Logo />

                <body>
                    <div className="image-overlaycc">
                        <img
                            src={Contactimgg}
                            alt="contact"
                            className="Contactimgg"
                        />
                        <div className="overlaytextcc">
                            <header>
                                <h1>Cartes De Visite</h1>
                                <body>
                                    {/* <h2>
                                        Nous vous offrons la possibilité de
                                        personnaliser entièrement votre carte de
                                        visite.
                                    </h2> */}
                                    <p>
                                        Vous avez le contrôle total sur chaque
                                        détail, afin de créer une carte qui
                                        correspond<br></br> parfaitement à votre
                                        style et à vos besoins.
                                    </p>

                                    <h3>
                                        Pourquoi Choisir Nos Cartes de Visite ?
                                    </h3>
                                    <ul>
                                        <li>
                                            <strong>Facile et Rapide:</strong>{" "}
                                            Pas besoin de compétences en design.
                                            Nous nous occupons<br></br> de tout,
                                            de la conception à l'impression.
                                        </li>
                                        <li>
                                            <strong>Qualité Garantie:</strong>{" "}
                                            Nos cartes sont imprimées sur du
                                            papier de haute qualité pour
                                            <br></br> une finition
                                            professionnelle.
                                        </li>
                                    </ul>

                                    <p>
                                        Avec nos options de personnalisation que
                                        ce soit le choix du papier, de la
                                        typographie,<br></br> des couleurs ou
                                        des finitions, vous choisissez les
                                        éléments
                                        {/* que vous souhaitez. Avec nos options de
                                        personnalisation , vous pouvez choisir
                                        les éléments que vous<br></br>{" "}
                                        souhaitez. Que ce soit le choix du
                                        papier, de la typographie, des couleurs
                                        ou des finitions, nous sommes là pour
                                        vous guider à chaque étape. */}
                                    </p>
                                </body>
                            </header>

                            <footer>
                                <p></p>
                            </footer>
                        </div>
                    </div>
                </body>
                <Footer />
            </div>
        </div>
    );
}

export default Carte;
