import React, { useEffect } from "react";
import Isolationimg from "../../assets/2.jpg";
import Isolationimg2 from "../../assets/2.jpg";
import Logo from "../../components/Logo/Logo";
import Footer from "../../components/Footer/Footer";
import "./Ecommerce.css";
function Ecommerce() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div class="page-containeriso">
            <div className="peinture-containeriso">
                <Logo />

                <div className="image-overlayiso">
                    <img
                        src={Isolationimg2}
                        alt="Isolation"
                        className="Isolationimgiso2"
                    />
                    <img
                        src={Isolationimg}
                        alt="Isolation"
                        className="Isolationimgiso"
                    />
                    <div className="image-overlaytextiso">
                        <body>
                            <h1>E-commerce Website Package</h1>
                            <p>
                                Lancez votre propre boutique en ligne avec notre
                                package E-commerce Website. Que vous vendiez des
                                produits physiques ou des services numériques,
                                notre solution complète vous offre tout ce dont
                                vous avez besoin pour réussir dans le monde du
                                commerce électronique.
                            </p>

                            <h2>Description</h2>
                            <p>
                                Notre package E-commerce Website comprend une
                                boutique en ligne entièrement fonctionnelle avec
                                toutes les caractéristiques nécessaires pour
                                vendre vos produits ou services en ligne. Voici
                                un aperçu des fonctionnalités incluses :
                            </p>
                            <ul>
                                <li>
                                    Pages Produits : Créez des pages détaillées
                                    pour chaque produit avec des descriptions,
                                    des images attrayantes et des prix
                                    clairement affichés.
                                </li>
                                <li>
                                    Panier d'Achat : Offrez une expérience de
                                    magasinage fluide à vos clients avec un
                                    panier d'achat intuitif qui leur permet
                                    d'ajouter et de gérer facilement leurs
                                    articles.
                                </li>
                                <li>
                                    Intégration de Passerelle de Paiement
                                    Sécurisé : Assurez la tranquillité d'esprit
                                    de vos clients en intégrant des passerelles
                                    de paiement sécurisées telles que PayPal,
                                    Stripe, ou d'autres options de paiement.
                                </li>
                                <li>
                                    Système de Gestion des Commandes : Suivez et
                                    gérez efficacement toutes les commandes
                                    passées sur votre boutique en ligne, de la
                                    réception à la livraison.
                                </li>
                                <li>
                                    Gestion des Stocks : Gardez une trace
                                    précise de votre inventaire pour éviter les
                                    ruptures de stock et optimiser la gestion
                                    des commandes.
                                </li>
                            </ul>

                            {/* <h2>Plage de Prix</h2>
                            <p>
                                Notre package E-commerce Website est proposé à
                                partir de 2 500 € jusqu'à 5 000 € et plus, selon
                                les fonctionnalités et les personnalisations
                                spécifiques à vos besoins. Nous travaillons en
                                étroite collaboration avec vous pour créer une
                                solution adaptée à votre entreprise et à votre
                                budget.
                            </p> */}

                            <p>
                                Que vous soyez un commerçant cherchant à étendre
                                votre présence en ligne ou une entreprise
                                établie souhaitant offrir une expérience d'achat
                                améliorée à vos clients, notre package
                                E-commerce Website est la solution idéale pour
                                vous lancer dans le commerce électronique avec
                                succès.
                            </p>

                            {/* <p>
                                Contactez-nous dès aujourd'hui pour discuter de
                                vos besoins en matière de commerce électronique
                                et commencer à construire votre boutique en
                                ligne dès maintenant !
                            </p> */}
                        </body>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
}
export default Ecommerce;
