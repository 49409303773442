import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import ErrorPage from "./Pages/Error/ErrorPage";
import Basic from "./Pages/Basic/Basic";
import Standard from "./Pages/Standard/Standard";
import Custom from "./Pages/Custom/Custom";
import Ecommerce from "./Pages/Ecommerce/Ecommerce";
import Logopage from "./Pages/Logopage/Logopage";
import Contact from "./Pages/Contact/Contact";
import Carte from "./Pages/Carte/Carte";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/ErrorPage" element={<ErrorPage />} />
            <Route path="/Basic" element={<Basic />} />
            <Route path="/Standard" element={<Standard />} />
            <Route path="/Custom" element={<Custom />} />
            <Route path="/Ecommerce" element={<Ecommerce />} />
            <Route path="/Logopage" element={<Logopage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/carte" element={<Carte />} />
            <Route path="*" element={<Navigate to="/ErrorPage" />} />
        </Routes>
    );
}

export default App;
