// Peinture.jsx
import React, { useEffect } from "react";
import "./Custom.css";
import Platreriesimg from "../../assets/3.jpg";
import Platreriesimg2 from "../../assets/Plaster2.jpg";
import Platreriesimgmin from "../../assets/3.jpg";
import Logo from "../../components/Logo/Logo";
import Footer from "../../components/Footer/Footer";

function Custom() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div class="page-container">
            <div>
                <Logo />

                <div className="image-overlaypl">
                    <img
                        src={Platreriesimgmin}
                        alt="Platreries"
                        className="Platreriesmin"
                    />
                    <img
                        src={Platreriesimg}
                        alt="Platreries"
                        className="Platreries"
                    />
                    <div className="image-overlaytextpl">
                        <body>
                            <header>
                                <h1>Custom Website Package</h1>
                            </header>
                            <main>
                                <section>
                                    <h2>Description :</h2>
                                    <p>
                                        Notre Custom Website Package est conçu
                                        pour les clients recherchant des
                                        solutions personnalisées avec des
                                        exigences spécifiques ou des préférences
                                        de design uniques. Que vous ayez besoin
                                        d'un site web pour une entreprise, un
                                        projet personnel ou une application en
                                        ligne, nous sommes là pour transformer
                                        vos idées en réalité.
                                    </p>
                                </section>
                                <section>
                                    <h2>Fonctionnalités :</h2>
                                    <ul>
                                        <li>
                                            Design et mise en page personnalisés
                                            : Nous travaillons en étroite
                                            collaboration avec vous pour créer
                                            un design unique et attrayant qui
                                            correspond à votre marque et à vos
                                            besoins.
                                        </li>
                                        <li>
                                            Fonctionnalités avancées : Nous
                                            pouvons intégrer des fonctionnalités
                                            avancées telles que l'enregistrement
                                            des utilisateurs, des zones
                                            réservées aux membres, et des
                                            intégrations d'API pour répondre à
                                            vos besoins spécifiques.
                                        </li>
                                        <li>
                                            Système de gestion de contenu (CMS)
                                            : Profitez d'un CMS convivial qui
                                            vous permet de mettre à jour
                                            facilement le contenu de votre site
                                            web sans avoir besoin de compétences
                                            techniques.
                                        </li>
                                        <li>
                                            Fonctionnalités avancées de
                                            référencement (SEO) : Nous
                                            optimisons votre site web pour les
                                            moteurs de recherche afin
                                            d'augmenter sa visibilité en ligne
                                            et d'attirer davantage de trafic
                                            qualifié.
                                        </li>
                                        <li>
                                            Intégration avec des services ou des
                                            plateformes tiers : Nous pouvons
                                            intégrer votre site web avec des
                                            services tiers tels que des systèmes
                                            de paiement, des réseaux sociaux, ou
                                            des outils d'analyse pour étendre
                                            ses fonctionnalités et sa portée.
                                        </li>
                                    </ul>
                                </section>
                                {/* <section>
                                    <h2>Plage de prix :</h2>
                                    <p>
                                        Le prix de notre Custom Website Package
                                        varie en fonction de la complexité du
                                        projet et des fonctionnalités requises.
                                        Nos tarifs commencent à partir de 3 000
                                        € et peuvent aller jusqu'à 10 000 € ou
                                        plus, en fonction des exigences
                                        spécifiques du projet.
                                    </p>
                                </section> */}
                            </main>
                            <footer>
                                <p>
                                    Que vous ayez besoin d'un site web simple ou
                                    d'une solution plus complexe, notre équipe
                                    est là pour créer un site web qui répond à
                                    vos attentes. N'hesitez pas à nous contacter
                                    pour obtenir un devis personnalisé.
                                </p>
                            </footer>
                        </body>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
}

export default Custom;
