import React, { useEffect, useState } from "react";
import logo from "../../assets/Logo.png";
import "./Logo.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

function Logo() {
    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/home");
        setTimeout(() => {
            const section = document.getElementById("services");
            if (section) {
                const scrollPosition = section.offsetTop + 1030;
                window.scrollTo({
                    top: scrollPosition,
                    behavior: "smooth",
                });
            }
        }, 100); // Adjust the delay as needed
    };

    return (
        <div className="Top">
            <NavLink to="/">
                <div className="mcrlogo">
                    <h3></h3>
                    <img src={logo} alt="logomcr" className="logoMcr" />
                </div>
            </NavLink>
            <nav>
                <div className="tab">
                    <NavLink to="/">
                        <div className="acc">
                            <h3>Accueil</h3>
                        </div>
                    </NavLink>
                    <div className="line"></div>
                    <div className="nos">
                        <h3 onClick={handleClick}>Nos Services</h3>
                    </div>
                    <div className="linea"></div>
                    <NavLink to="/contact">
                        <h3>Contact</h3>
                    </NavLink>
                </div>
            </nav>
        </div>
    );
}

export default Logo;
